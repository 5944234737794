/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {useLocation, Link as RouterLink} from 'react-router-dom';
import {getCallNowPopupProps, getHomeLocation} from '../Common';
import clsx from 'clsx';
import Application from '../config/application';
import {PageUrl} from '../Constants';
import PropTypes from 'prop-types';
import CallNowPopup from './CallNowPopup';
import { CallNowPopupProps } from '../model';

const Header = React.memo(({phone, phoneCall, popupProps}) => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <header id="header">
        <div className="container">
          <div className="header-row">
            <div className="header-column justify-content-start">
              {/* Logo
        ============================================= */}
              <div className="logo">
                <RouterLink to={getHomeLocation(location)}>
                  <img
                    src={`${
                      Application.STORAGE_URL_STATIC_ASSETS_PATH
                    }/logo-${Application.ORG.toLowerCase()}.png`}
                    alt={Application.ORG_DISPLAY_NAME}
                    width={200}
                  />
                </RouterLink>
              </div>
              {/* Logo end */}
            </div>

            <div className="header-column justify-content-end">
              {/* Primary Navigation
        ============================================= */}
              <nav className="primary-menu navbar navbar-expand-lg">
                <div
                  id="header-nav"
                  className={clsx(
                    'collapse navbar-collapse',
                    showMenu && 'show'
                  )}>
                  <ul className="navbar-nav">
                    <li className="dropdown">
                      {' '}
                      <RouterLink to={getHomeLocation(location)}>
                        Home
                      </RouterLink>
                    </li>
                    <li className="dropdown">
                      {' '}
                      <RouterLink to={PageUrl.ABOUT}>About Us</RouterLink>
                    </li>
                    <li className="dropdown">
                      {' '}
                      <RouterLink to={PageUrl.DESTINATION}>
                        Destinations
                      </RouterLink>
                    </li>
                    <li className="dropdown">
                      {' '}
                      <RouterLink to={PageUrl.CONTACT}>Contact Us</RouterLink>
                    </li>
                    {/*<li className="login-signup ml-lg-2">
                    <RouterLink
                      className="pl-lg-4 pr-0"
                      to={`${PageUrl.BOOKING_CHECK}`}>
                      Check my booking
                    </RouterLink>
      </li>*/}
                    <li className="dropdown calling">
                      <a href={`tel:${phoneCall}`}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        {phone}
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* Primary Navigation end */}
            </div>
            <button
              className={clsx('navbar-toggler', showMenu && 'open')}
              type="button"
              data-toggle="collapse"
              data-target="#header-nav"
              onClick={() => setShowMenu((s) => !s)}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </header>
      {<CallNowPopup popupProps = {popupProps}/>}
    </>
  );
});

Header.propTypes = {
  phone: PropTypes.string,
  phoneCall: PropTypes.string,
  popupProps: PropTypes.instanceOf(CallNowPopupProps)
};

Header.defaultProps = {
  phone: Application.SUPPORT_PHONE,
  phoneCall: Application.CALL_NOW_POPUP_LINKED_PHONE,
  popupProps: getCallNowPopupProps()
};

export default Header;
