import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('About Us')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>About Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-6">Learn More About Us</h2>
            <p>
              Welcome to {Application.ORG_DISPLAY_NAME}.net! We are a domestic
              and worldwide online travel agency known for our ideals, devotion,
              and passion to client service. Our global relationship with the
              most recognized travel providers enables us to provide unique
              benefits to our customers. We provide a wide range of travel
              services and products to make both business and leisure travel
              more pleasurable. With us, you'll never be far from helpful
              planning and booking assistance, whether it's before or after your
              trip.
            </p>
            <p>
              Along with giving you an amazing and inspiring trip,{' '}
              {Application.ORG_DISPLAY_NAME} Partners is dedicated to making
              sure you are happy and comfortable at all times. You can have
              peace of mind when you travel with us since we have people on duty
              around-the-clock to fulfill your requests and demands.{' '}
              {Application.ORG_DISPLAY_NAME} Partners will make sure that your
              vacation is effortless, authentic, and better than you could have
              imagined from the moment you pick up the phone until you arrive
              home.
            </p>
            <p>
              For all of your upcoming travel requirements, reserve your plane
              tickets using our user-friendly website, mobile site, and two-step
              checkout method. We take great pleasure in having a staff of
              travel specialists available around-the-clock to assist with any
              need.
            </p>
            <p>
              Our travel experts are here to help you see the world and simplify
              your travel experience 24/7 and work hard to derive the best fares
              from top airlines around the world. We do understand that cost
              plays a significant role when planning your next vacation or
              traveling for business, but we do not want to compromise on
              comfort and luxury.
            </p>
            <div className="row mt-4 mb-2">
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-thumbs-up"></i>
                  </div>
                  <h3>Best Deal Offer</h3>
                  <p>
                    Find our lowest deal to destinations worldwide, guaranteed
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-paper-plane"></i>{' '}
                  </div>
                  <h3>Easy Booking</h3>
                  <p>
                    Search, select and save - the fastest way to book your trip
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-eye"></i>{' '}
                  </div>
                  <h3>24/7 Customer Care</h3>
                  <p>
                    Get award-winning service and special deals by calling{' '}
                    {Application.SUPPORT_PHONE}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
