import React from 'react';
import {Helmet} from 'react-helmet-async';
import Box from '@material-ui/core/Box';
import random from 'lodash-es/random';
import {
  composePageTitle,
  getAirline,
  getAirport,
  getCallNowPopupProps,
  getListingURLWithQS,
  normalizeAirportCity,
} from './Common';
import './vendor/bootstrap/css/bootstrap.min.css';
import './css/stylesheet.css';
import './css/custom.css';
import Header from './components/Header';
import ListingSearch from './components/ListingSearch';
import Loader from './components/Loader';
import Footer from './components/Footer';
import {useQuery} from 'react-query';
import {PAGE_IDS, QueryKeys} from './Constants';
import {airlinesFetch, airportsFetch, flightDealsFetch} from './api/fetches';
import clsx from 'clsx';
import {formatDate, roundFormatMoney} from './Util';
import {addDays} from 'date-fns';
import Application from './config/application';

const DISPLAY_DATE_FORMAT = 'MMM dd, yyyy';

const popupProps = getCallNowPopupProps(
  null,
  null,
  null,
  Application.PAGE_ID_TO_PHONE[PAGE_IDS.FLIGHT],
  null,
  null,
  Application.PAGE_ID_TO_CALL_NOW_POPUP_LINKED_PHONE[PAGE_IDS.FLIGHT]
);

const Flight = () => {
  const {data: deals} = useQuery([QueryKeys.DEALS], flightDealsFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {data: airports} = useQuery([QueryKeys.AIRPORTS], airportsFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {data: airlines} = useQuery([QueryKeys.AIRLINES], airlinesFetch, {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const createDeals = () => {
    const totalDeals = deals.length;
    if (totalDeals === 0 || totalDeals % 2 !== 0) {
      throw new Error('Expected deals to exist and its size to be an even.');
    }
    const rows = [];
    for (let i = 0; i < totalDeals; ) {
      const row = (
        <div className={clsx('row', i >= 2 && 'mt-3')} key={i}>
          {[deals[i], deals[i + 1]].map((d, index) => {
            // Show start dates at an offset of 3 to 10 days
            const depDate = addDays(new Date(), random(3, 10));
            const retDate = addDays(depDate, d.dayRange);
            const airline = getAirline(airlines, d.airlineCode);
            return (
              <div className="col-lg-6" key={index}>
                <div className="flight-item">
                  <div className="row align-items-center flex-row py-4 px-3">
                    <div className="text-center d-lg-flex company-info">
                      {' '}
                      <span className="align-middle">
                        <img
                          className="img-fluid ml-3"
                          alt={airline.name}
                          src={airline.logo}
                          style={{width: '3.5rem'}}></img>
                      </span>{' '}
                    </div>
                    <div className="col-5 col-sm-auto text-center text-sm-start">
                      <span className="align-middle">
                        <span
                          className="d-block text-0"
                          style={{whiteSpace: 'nowrap'}}>
                          {normalizeAirportCity(
                            getAirport(airports, d.fromLocation).city
                          )}
                        </span>
                        <small className="text-muted d-block">
                          {formatDate(depDate, DISPLAY_DATE_FORMAT)}
                        </small>
                      </span>
                    </div>
                    <div className="col-2 col-sm-auto text-8 text-black-50 py-2 text-center trip-arrow">
                      ⇄
                    </div>
                    <div className="col-5 col-sm-auto text-center text-sm-start">
                      <span className="align-middle">
                        <span
                          className="d-block text-0"
                          style={{whiteSpace: 'nowrap'}}>
                          {normalizeAirportCity(
                            getAirport(airports, d.toLocation).city
                          )}
                        </span>
                        <small className="text-muted d-block">
                          {formatDate(retDate, DISPLAY_DATE_FORMAT)}
                        </small>
                      </span>
                    </div>
                    <div style={{flexGrow: 1}}>
                      <div className="d-block py-3 col-sm-auto text-end text-dark text-6 price text-right text-sm-start">
                        {roundFormatMoney(d.price)}
                      </div>
                      <div className="d-block col-sm-auto text-right ms-auto btn-book">
                        {' '}
                        <a
                          href={getListingURLWithQS(
                            d.fromLocation,
                            d.toLocation,
                            depDate,
                            retDate
                          )}
                          className="btn btn-sm btn-primary">
                          <i className="fa fa-plane mr-2"></i> <span>Book</span>
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
      rows.push(row);
      i = i + 2;
    }
    return rows;
  };

  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle(`Find Cheap Flights with us`)} />
      <Header
        phone={Application.PAGE_ID_TO_PHONE[PAGE_IDS.FLIGHT]}
        popupProps={popupProps}
      />
      <div id="content">
        <div className="search-container">
          <div className="overlay overlay3"></div>
          <section className="container">
            <ListingSearch searchText="Search" />
          </section>
        </div>
        <div className="section bg-light shadow-md">
          <div className="container">
            <div className="flight-list">
              <div className="headingtxt mb-3">
                <h3 className="headh3 txt-ff" data-aos="zoom-out-up">
                  Top Flights Deals - <small>Get Latest Flight Deals</small>
                </h3>
              </div>
              <div className="container">
                {deals && airlines && airports ? (
                  createDeals()
                ) : (
                  <Box width="100%">
                    <Loader rows={4} />
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer phone={Application.PAGE_ID_TO_PHONE[PAGE_IDS.FLIGHT]} />
    </div>
  );
};

export default Flight;
