function Fare(
  sourceCode,
  directionIndicator,
  fareType,
  passportRequired,
  refundable,
  baseFare,
  totalTax,
  baggage,
  cabinBaggage,
  perAdultTotalFare,
  itineraries,
  pax
) {
  this.sourceCode = sourceCode;
  this.directionIndicator = directionIndicator;
  this.fareType = fareType;
  this.passportRequired = passportRequired;
  this.refundable = refundable;
  this.baseFare = baseFare;
  this.totalTax = totalTax;
  this.baggage = baggage;
  this.cabinBaggage = cabinBaggage;
  this.perAdultTotalFare = perAdultTotalFare;
  this.itineraries = itineraries;
  this.pax = pax;
}

function Itinerary(totalStops, stops) {
  this.totalStops = totalStops; // 0 when none, else 1 or more.
  this.stops = stops;
}

function Stop(
  departureAirportLocationCode,
  departureDateTime,
  arrivalAirportLocationCode,
  arrivalDateTime,
  journeyDurationMins,
  cabinClassCode,
  cabinClassText,
  flightNumber,
  airlineCode,
  planeCode,
  seatsRemaining
) {
  this.departureAirportLocationCode = departureAirportLocationCode;
  this.departureDateTime = departureDateTime;
  this.arrivalAirportLocationCode = arrivalAirportLocationCode;
  this.arrivalDateTime = arrivalDateTime;
  this.journeyDurationMins = journeyDurationMins;
  this.cabinClassCode = cabinClassCode;
  this.cabinClassText = cabinClassText;
  this.flightNumber = flightNumber;
  this.airlineCode = airlineCode;
  this.planeCode = planeCode;
  this.seatsRemaining = seatsRemaining;
}

function EmailSettings(
  senderEmail,
  senderName,
  senderCompany,
  senderDomain,
  senderSupportPhone
) {
  this.senderEmail = senderEmail;
  this.senderName = senderName;
  this.senderCompany = senderCompany;
  this.senderDomain = senderDomain;
  this.senderSupportPhone = senderSupportPhone;
}

function Customer(email, phone, utcOffsetInMinutes) {
  this.email = email;
  this.phone = phone;
  this.utcOffsetInMinutes = utcOffsetInMinutes;
}

function CustomerCard(
  country,
  state,
  address,
  city,
  postal,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvv,
  firstName,
  lastName
) {
  this.country = country;
  this.state = state;
  this.address = address;
  this.city = city;
  this.postal = postal;
  this.cardNumber = cardNumber;
  this.expiryMonth = expiryMonth;
  this.expiryYear = expiryYear;
  this.cvv = cvv;
  this.firstName = firstName;
  this.lastName = lastName;
}

function Passenger(
  type,
  title,
  firstName,
  middleName,
  lastName,
  dob,
  gender,
  passportIssuanceCountry,
  passportExpiryDate,
  passportNumber
) {
  this.type = type;
  this.title = title;
  this.firstName = firstName;
  this.middleName = middleName;
  this.lastName = lastName;
  this.dob = dob;
  this.gender = gender;
  this.passportIssuanceCountry = passportIssuanceCountry;
  this.passportExpiryDate = passportExpiryDate;
  this.passportNumber = passportNumber;
}

function FlightDeal(airlineCode, fromLocation, toLocation, dayRange, price) {
  this.airlineCode = airlineCode;
  this.fromLocation = fromLocation;
  this.toLocation = toLocation;
  this.dayRange = dayRange;
  this.price = price;
}

function FareRule(airline, category, rules) {
  this.airline = airline;
  this.category = category;
  this.rules = rules;
}

function ValidateFare(noFullBooking, valid, fare) {
  this.noFullBooking = noFullBooking;
  this.valid = valid;
  this.fare = fare;
}

function BookingResult(success, paymentError, bookingRefNumber, chargeSuccess, partiallyBooked) {
  this.success = success;
  this.paymentError = paymentError;
  this.bookingRefNumber = bookingRefNumber;
  this.chargeSuccess = chargeSuccess;
  this.partiallyBooked = partiallyBooked;
}

function SnackbarAlertProps(
  message = null,
  type = null,
  verPos = null,
  horPos = null,
  autoHideDuration = null
) {
  this.message = message;
  this.type = type;
  this.verPos = verPos;
  this.horPos = horPos;
  this.autoHideDuration = autoHideDuration;
}

function Airport(code, name, city, country) {
  this.code = code;
  this.name = name;
  this.city = city;
  this.country = country;
}

function Airline(code, name, logo) {
  this.code = code;
  this.name = name;
  this.logo = logo;
}

function CallNowPopupProps(savePrice, timeToPop, headingText, phone, showPopup, hideVisiblePhones, linkedPhone) {
  this.savePrice = savePrice;
  this.timeToPop = timeToPop;
  this.headingText = headingText;
  this.phone = phone;
  this.showPopup = showPopup;
  this.hideVisiblePhones = hideVisiblePhones;
  this.linkedPhone = linkedPhone;
}

export {
  Fare,
  Itinerary,
  Stop,
  EmailSettings,
  Customer,
  CustomerCard,
  Passenger,
  FlightDeal,
  FareRule,
  ValidateFare,
  BookingResult,
  SnackbarAlertProps,
  Airline,
  Airport,
  CallNowPopupProps
};
